// taken from this fiddle: https://jsfiddle.net/e4h9fj1c/2/

// Exif orientation value to css transform mapping
// Does not include flipped orientations
export const rotation = {
    1: 'rotate(0deg)',
    3: 'rotate(180deg)',
    6: 'rotate(90deg)',
    8: 'rotate(270deg)'
}
 
function _arrayBufferToBase64(buffer: any) {
    let binary = ''
    const bytes = new Uint8Array(buffer)
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary);
}

export type Orientation = {
    image: string,
    value: number
}

type Resolve = (value: Orientation) => void

export const getOrientation = (file: Blob | File, callback: Resolve) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
        // @ts-ignore
        if (!fileReader.result) {
            return
        }
        const type = file.type
        const base64img = "data:" + type + ";base64," + _arrayBufferToBase64(fileReader.result);
        // @ts-ignore
        const scanner = new DataView(fileReader.result);
        let idx = 0;
        let value = 1; // Non-rotated is the default
        // @ts-ignore

        const length = fileReader.result.length
        

        if ((length && length < 2) || scanner.getUint16(idx) != 0xFFD8) {
            // Not a JPEG
            callback({image: base64img, value})
        }
        idx += 2;
        let maxBytes = scanner.byteLength;
        while (idx < maxBytes - 2) {
            const uint16 = scanner.getUint16(idx);
            idx += 2;
            switch (uint16) {
            case 0xFFE1: // Start of EXIF
                const exifLength = scanner.getUint16(idx);
                maxBytes = exifLength - idx;
                idx += 2;
                break;
            case 0x0112: // Orientation tag
                // Read the value, its 6 bytes further out
                // See page 102 at the following URL
                // http://www.kodak.com/global/plugins/acrobat/en/service/digCam/exifStandard2.pdf
                value = scanner.getUint16(idx + 6, false);
                maxBytes = 0; // Stop scanning
                break;
            }
        }
        callback({image: base64img, value});
    }
    fileReader.readAsArrayBuffer(file);
}

// Logic for working with File objects
// taken from https://stackoverflow.com/questions/5933565/how-to-create-initialize-the-file-object-using-file-path-html5

const getFileBlob = function (url: any, cb: any) {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener('load', function() {
        cb(xhr.response);
    });
    xhr.send();
};

const blobToFile = function (blob: any, name: any) {
    blob.lastModifiedDate = new Date();
    blob.name = name;
    return blob;
};

export const getFileObject = function(filePathOrUrl: any, cb: any) {
   getFileBlob(filePathOrUrl, function (blob: any) {
      cb(blobToFile(blob, filePathOrUrl));
   });
};

