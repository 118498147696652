import React from 'react';
import './App.css';

import {getOrientation, Orientation, rotation, getFileObject} from './utils/media'

const files: string[] = require('./manifest.json')
// hack to remove last video
files.pop()

const getRandomIndex = () => Math.floor(Math.random() * files.length)

type Props = {}
type State = {
  textValue: string
  index: number
  orientation: Orientation | undefined
}

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const index = getRandomIndex()
    this.state = {
      textValue: (index + 1).toString(),
      index,
      orientation: undefined
    }
  }

  increaseIndex = () => this.changeLogo(this.state.index + 1)
  decreaseIndex = () => this.changeLogo(this.state.index - 1)


  changeIndex = (e: any) => {
    e.persist()
    this.setState({textValue: e.target.value})
  }

  handleKeyDown = (e: any) => {
    e.persist()
    if (e.key === "Enter") {
      const value = Number(e.target.value)

      this.changeLogo(value - 1)
   }
  }

  getRandomLogo = () => {
    const index = getRandomIndex()
    this.changeLogo(index)
  }

  changeLogo = (index: number) => {
    if (index < 0 || index >= files.length) {
      window.alert("Value is invalid")
      return
    }
    this.setState({index, textValue: (index + 1).toString(), orientation: undefined})
  }

  setOrientation = () => {
    if (!this.state.orientation) {
      const logo = files[this.state.index]
      getFileObject(logo, (fileObject: File | Blob) => {
        getOrientation(fileObject, o => {
          this.setState({orientation: o})
        })
       }); 
    }
  }
  getDisplayMedia = (logo: string) => {
    let displayMedia
    const extension = logo.split(".").pop()?.toLowerCase()
    switch (extension) {
      case "mov":
      case "mp4":
        displayMedia = <video className="App-logo" src={logo} autoPlay onClick={this.getRandomLogo}/>
        break
      case "jpg":
      default:
        if (this.state.orientation && this.state.orientation.value) {
          // @ts-ignore
          displayMedia = <img src={logo} className="App-logo" alt="logo" style={{transform: rotation[this.state.orientation.value]}} onClick={this.getRandomLogo}/>
          break
        }
        displayMedia = <img src={logo} className="App-logo" alt="logo" onClick={this.getRandomLogo} />
        break
    }
    return displayMedia
  }



  render() {
    const logo = files[this.state.index]

    let displayMedia
    if (!this.state.orientation) {
      this.setOrientation()
      displayMedia = <div>Loading</div>
    } else {
      displayMedia = this.getDisplayMedia(logo)
    }
    const disablePrevious = this.state.index < 1
    const disableNext = this.state.index >= files.length - 1
    return (
      <div className="App">
        <h1>In Memoriam: Sebastian</h1>
        <header className="App-header">
          <div style={{
            height: '60vh',
            width: '100%',
            borderRadius: '50%',
            alignItems: 'center'
          }}>
            {displayMedia}
          </div>
          <div style={{display: 'flex'}}>
            <button className="button medium" onClick={this.decreaseIndex} disabled={disablePrevious}>Previous</button>
            <span className="spanIndex">
              <input 
                className="inputBlank"
                style={{width: "2.5em"}}
                type="number"
                name="index"
                value={this.state.textValue}
                onKeyDown={this.handleKeyDown}
                onChange={this.changeIndex}
              />
              {`/${files.length}`}
            </span>
            <button className="button medium" onClick={this.increaseIndex} disabled={disableNext}>Next</button>
          </div>
          <blockquote>{`He's the George Clooney of pugs; He keeps getting better with age.`}</blockquote>
          <a href="http://pugalug.com/donate/" target="_blank" rel="noopener noreferrer">Donate to Pugalug Pug Rescue</a>
        </header>
      </div>
    )
  }
}

export default App;
